<template>
    <b-card-actions action-collapse title="Room Type">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-form-group>
                            <label>Room Type Name</label>
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="name"
                            >
                                <b-form-input
                                    v-model="input.name"
                                    placeholder="room type name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button
                            variant="primary"
                            type="submit"
                            @click.prevent="validateForm"
                        >
                            Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-actions>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch, computed, onUnmounted, onMounted } from '@vue/composition-api'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import store from '@/store';
import furnitureStoreModule from '../furnitureStoreModule'
import { useToast } from "vue-toastification/composition";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default {
    components: {
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormTextarea,
        BFormRating,
        vSelect,
        BFormCheckbox,
        BFormCheckboxGroup,
        BCardActions,
        ValidationProvider,
        ValidationObserver,
    },

    setup() {
        const FURNITURE_APP_STORE_MODULE_NAME = "app-furniture";

        if (!store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
            store.registerModule(FURNITURE_APP_STORE_MODULE_NAME, furnitureStoreModule);
        }

        onUnmounted(() => {
            if (store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(FURNITURE_APP_STORE_MODULE_NAME);
            }
        });

        const userData = JSON.parse(localStorage.getItem('userData'));
        const input = ref({
            name: '',
            created_by: userData.id,
            updated_by: userData.id,
        })
        const simpleRules = ref(null)
        const toast = useToast();
        const roomTypeId = ref(router.currentRoute.params.id)

        const getData = () => {
            if(roomTypeId.value){
                store
                    .dispatch("app-furniture/getRoomTypeData", {
                        id: roomTypeId.value
                    })
                    .then((response) => {
                        input.value = response.data.data[0]
                    }).catch((error) => {
                        this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                    })
            }
        }

        onMounted(() => {
            getData();
        });

        const validateForm = async () => {
            try {
                const isValid = await simpleRules.value.validate();
                if (!isValid) return;

                let action;
                let successMessage;

                if (roomTypeId.value) {
                    action = store.dispatch('app-furniture/updateRoomTypeData', {
                        data: input.value,
                    });
                    successMessage = 'You have successfully updated.';
                } else {
                    action = store.dispatch('app-furniture/addRoomTypeData', input.value);
                    successMessage = 'You have successfully inserted.';
                }

                await action;

                router.push('/inventory-lists/room-type');
                showToast('success', 'Done', successMessage);
            } catch (error) {
                showToast(
                    'danger',
                    'Error',
                    error?.response?.data?.message || 'Something went wrong'
                );
            }
        };

        const showToast = (variant, title, message) => {
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title,
                    icon: variant === 'success' ? 'CoffeeIcon' : 'AlertTriangleIcon',
                    variant,
                    text: message,
                },
            });
        };

        return {
            input,
            validateForm,
            simpleRules,
            getData,
            roomTypeId
        }
    },

    methods: {
        makeToast(variant = null, title, message) {
            this.$bvToast.toast(message, {
                title: title,
                variant,
                solid: true,
            })
        },
    },
}
</script>